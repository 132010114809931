<template>
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.34116 17.2245C12.9477 17.2245 16.6808 13.5423 16.6808 9.0002C16.6808 4.45816 12.9477 0.775879 8.34116 0.775879C3.73459 0.775879 0 4.45816 0 9.0002C0 13.5423 3.73459 17.2245 8.34116 17.2245ZM3.72083 8.56807C3.73156 11.6656 6.11606 13.5937 8.40708 13.5861C11.236 13.577 12.9094 11.2863 12.9033 9.19508C12.8956 7.10539 11.7432 5.59439 9.2039 4.98395C9.19779 4.95825 9.19323 4.90233 9.19323 4.84948C9.18863 3.27048 10.3962 1.87736 11.9133 1.5676C11.7708 1.51925 11.5364 1.50114 11.3126 1.50114C9.57787 1.50719 7.66384 2.24003 6.26624 3.37931C4.83492 4.54882 3.71316 6.52067 3.72083 8.56807Z"
      fill="#EE3023"
    />
  </svg>
</template>
