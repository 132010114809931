<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.77471 6.25035L7.77028 6.25031C7.90055 6.24946 8.0294 6.22325 8.14963 6.17311C8.27127 6.12244 8.3817 6.04826 8.47469 5.9549C8.56763 5.86153 8.64129 5.75073 8.69148 5.6289C8.74163 5.50707 8.76727 5.37652 8.76698 5.24475V1.5547C8.78009 1.41591 8.76406 1.27592 8.71995 1.14368C8.67579 1.01145 8.60453 0.889885 8.51069 0.786783C8.41691 0.683682 8.30257 0.601312 8.17503 0.544949C8.04755 0.488586 7.90969 0.459473 7.77028 0.459473C7.63087 0.459473 7.49302 0.488586 7.36549 0.544949C7.238 0.601312 7.12366 0.683682 7.02983 0.786783C6.93599 0.889885 6.86473 1.01145 6.82062 1.14368C6.77651 1.27592 6.76048 1.41591 6.77354 1.5547V5.24475C6.77325 5.37652 6.79894 5.50707 6.84909 5.6289C6.89923 5.75073 6.9729 5.86153 7.06588 5.9549C7.15882 6.04826 7.2693 6.12244 7.39089 6.17311C7.51117 6.22325 7.64002 6.24946 7.77028 6.25031L7.76585 6.25035H7.77471Z"
      fill="#0087E6"
    />
    <path
      d="M5.43576 7.95805C5.39575 8.08347 5.33141 8.19974 5.24634 8.30022C5.16127 8.4007 5.05717 8.48336 4.94005 8.54345C4.82293 8.60354 4.6951 8.63993 4.56388 8.65044C4.43266 8.66095 4.30065 8.64544 4.17545 8.60482L0.68472 7.46409C0.434013 7.37972 0.226665 7.19992 0.107606 6.96366C-0.0114538 6.72745 -0.0326336 6.45381 0.0486558 6.20209C0.129945 5.95037 0.307155 5.74079 0.541884 5.61881C0.776618 5.49684 1.04996 5.47224 1.30269 5.55043L4.79343 6.69111C5.04642 6.7742 5.2561 6.95424 5.37656 7.19177C5.49698 7.42926 5.51824 7.70483 5.43576 7.95805Z"
      fill="#0087E6"
    />
    <path
      d="M10.3033 8.30088C10.2181 8.20025 10.1536 8.0837 10.1137 7.95805C10.0318 7.70483 10.0533 7.42954 10.1737 7.19215C10.294 6.9548 10.5033 6.77467 10.756 6.69111L14.2489 5.55043C14.5016 5.47224 14.775 5.49684 15.0097 5.61881C15.2445 5.74079 15.4217 5.95037 15.503 6.20209C15.5843 6.45381 15.5631 6.72745 15.444 6.96366C15.325 7.19992 15.1176 7.37972 14.8669 7.46409L11.3762 8.60482C11.2508 8.64577 11.1186 8.66156 10.9871 8.65119C10.8557 8.64082 10.7276 8.60453 10.6102 8.54444C10.4928 8.4843 10.3885 8.40154 10.3033 8.30088Z"
      fill="#0087E6"
    />
    <path
      d="M2.99267 15.3404C2.77749 15.1828 2.63344 14.9464 2.59194 14.6829C2.55044 14.4194 2.61488 14.1501 2.77117 13.934L4.9285 10.9593C5.00561 10.8528 5.10293 10.7626 5.21496 10.6938C5.32698 10.625 5.4515 10.579 5.58134 10.5584C5.71118 10.5378 5.84381 10.5431 5.97162 10.5739C6.09944 10.6047 6.2199 10.6604 6.32613 10.7378C6.54133 10.8955 6.68536 11.1319 6.72688 11.3954C6.76835 11.6589 6.70393 11.9281 6.54764 12.1443L4.3925 15.1167C4.31522 15.2233 4.21767 15.3135 4.10545 15.3824C3.99323 15.4512 3.86854 15.4972 3.73852 15.5178C3.6085 15.5384 3.47569 15.5331 3.34771 15.5023C3.21973 15.4715 3.09908 15.4157 2.99267 15.3382V15.3404Z"
      fill="#0087E6"
    />
    <path
      d="M12.9575 14.6829C12.916 14.9464 12.7719 15.1828 12.5567 15.3404V15.3493C12.4505 15.4267 12.33 15.4825 12.2022 15.5132C12.0744 15.544 11.9418 15.5492 11.8119 15.5287C11.6821 15.5081 11.5576 15.4621 11.4456 15.3933C11.3335 15.3245 11.2362 15.2343 11.1591 15.1278L9.00179 12.1421C8.92412 12.0356 8.86817 11.9149 8.83716 11.7868C8.80615 11.6588 8.80068 11.5258 8.82104 11.3957C8.8414 11.2654 8.88721 11.1405 8.95583 11.028C9.02446 10.9155 9.11461 10.8177 9.22103 10.74C9.3275 10.6624 9.44825 10.6064 9.5763 10.5754C9.7044 10.5444 9.8373 10.5389 9.96747 10.5593C10.0977 10.5796 10.2226 10.6255 10.3351 10.6941C10.4476 10.7627 10.5454 10.8529 10.6231 10.9593L12.7782 13.934C12.9345 14.1501 12.999 14.4194 12.9575 14.6829Z"
      fill="#0087E6"
    />
  </svg>
</template>
