<template>
  <div v-if="data.length" class="links">
    <a
      v-for="item in data"
      :key="item.propertyName"
      :href="item.propertyValue"
      rel="nofollow"
      target="_blank"
      class="link"
      :class="
        largerMobile ? 'h-9 w-9 md:h-[30px] md:w-[30px]' : 'h-[30px] w-[30px]'
      "
    >
      <component
        :is="icons[item.propertyName] || icons.default"
        :class="largerMobile ? 'h-4 md:h-3.5' : 'h-3.5'"
      />
    </a>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useSettingsStore } from '~/stores/settings'

const props = defineProps({
  largerMobile: {
    type: Boolean,
    default: false
  },
  messengersData: {
    type: Array,
    default: () => []
  }
})

const { messengers } = storeToRefs(useSettingsStore())

const data = computed(() => {
  const result = props.messengersData?.length
    ? props.messengersData
    : messengers.value
  return result.map(el => ({
    ...el,
    propertyName: el.propertyName?.replace(SETTINGS.COMMISSION_PREFIX, '')
  }))
})

const icons = shallowRef({
  default: resolveComponent('IconsSend'),
  messenger_viber: resolveComponent('IconsMessengersViber'),
  messenger_telegram: resolveComponent('IconsMessengersTelegram'),
  messenger_instagram: resolveComponent('IconsMessengersInstagram'),
  messenger_facebook: resolveComponent('IconsMessengersMessenger'),
  messenger_skype: resolveComponent('IconsMessengersSkype')
})
</script>

<style lang="postcss" scoped>
.links {
  @apply flex gap-2 items-center max-w-fit flex-wrap;
}
.link {
  @apply rounded-full flex items-center justify-center bg-gray shrink-0;
}
</style>
